<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { i18n, t } from '@/common/i18n'
import { FilterMatchMode } from '@primevue/core/api'
import { useToast } from 'primevue/usetoast'
import { storeToRefs } from 'pinia'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { useApi } from '@/stores/api/api'
import { ApiService } from '@/services/ApiService'
import { capitalizeString } from '@/utils/capitalize'
import clock from '@/assets/lottie/clock.json'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import BasePaginator from '@/components/ui/BasePaginator.vue'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import router from '@/router'
import AppTopbar from '@/layout/AppTopbar.vue'
import ContractCreationDialog from '@/components/contracts/ContractCreationDialog.vue'
import type { Contract, ContractResponse } from '@/models/domain/contract/api/Contract'
import { useAuthStore } from '@/stores/auth'
import { Roles } from '@/models'

const { loading } = storeToRefs(useApi())
const popup = ref()
const updatedContract = ref<Partial<Contract>>()
const activeCreationDialog = ref<boolean>(false)
const activeUpdateDialog = ref<boolean>(false)
const filters = ref({
  global: { value: undefined, matchMode: FilterMatchMode.CONTAINS }
})
const contracts = ref<Contract[]>([])
const totalRecords = ref<number>(0)
const rowsPerPage = ref<number>(10)
const currentPage = ref<number>(0)
const { role: userRole } = storeToRefs(useAuthStore())
const toast = useToast()

const getContracts = async () => {
  try {
    const response = await ApiService.readAllEntities<ContractResponse>(
      `${RouteNamespace.contracts}?limit=${rowsPerPage.value}&offset=${currentPage.value}`
    )
    contracts.value = response['contracts']
    totalRecords.value = response['totalRecords']
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  }
}
const handleCreate = () => {
  activeCreationDialog.value = true
}
const handleUpdate = (row: Partial<Contract>) => {
  updatedContract.value = {
    id: row.id,
    address: row.address,
    city: row.city,
    country: row.country,
    email: row.email,
    identityNumber: row.identityNumber,
    name: row.name,
    phone: row.phone,
    postcode: row.postcode,
    province: row.province,
    reference: row.reference
  }
  activeUpdateDialog.value = true
}
const actionsCloseContract = async (contract: string) => {
  try {
    const payload = {
      isDeleted: true
    }
    await ApiService.updateEntity(RouteNamespace.contracts, contract['id'], payload)
    toast.add({
      group: 'success',
      severity: 'success',
      summary: i18n.global.t('detail.contract.notifications.deleteSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    await getContracts()
  }
}
const handleRemove = async (event: Event, row: string) => {
  popup.value.showConfirmPopup(
    event,
    async () => {
      loading.value = true
      await actionsCloseContract(row)
      loading.value = false
    },
    undefined
  )
}

onMounted(async () => {
  try {
    loading.value = true
    await getContracts()
    //open modal dialog from query param
    if (router.currentRoute.value.query.active) {
      activeCreationDialog.value = true
    }
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    loading.value = false
  }
})
</script>
<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div class="flex flex-row ml-2 justify-content-between align-items-center">
          <div class="flex">
            <svg-icon name="contract" size="24" color="#626868" />
            <span class="font-bold ml-2 text-2xl">{{ t('dashboard.contracts') }}</span>
          </div>
          <Button
            v-if="!(userRole.name === Roles.support)"
            v-tooltip.top="t('detail.contract.actions.create')"
            class="button button-normal ml-3"
            rounded
            @click="handleCreate"
          >
            <template #icon>
              <svg-icon name="add" size="18" color="#626868" />
            </template>
          </Button>
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex w-3">
        <IconField icon-position="left">
          <InputIcon class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            :placeholder="t('search')"
            type="text"
            :pt="{
              root: {
                class: ['border-300']
              }
            }"
          />
        </IconField>
      </div>
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <DataTable
      v-model:filters="filters"
      :value="contracts"
      :globalFilterFields="['name', 'reference', 'identityNumber']"
      :rows="rowsPerPage"
      dataKey="id"
      scrollable
    >
      <template #empty>
        <BaseLottieAnimation :icon="clock" :label="t('detail.contract.notFound')" />
      </template>
      <Column
        field="reference"
        :header="t('detail.contract.header.reference')"
        class="table__reference"
      >
        <template #body="slotProps">
          <Tag
            severity="warn"
            :value="slotProps.data['reference'].toUpperCase()"
            class="w-full"
            rounded
          />
        </template>
      </Column>
      <Column field="name" :header="t('detail.contract.header.name')" class="table__name">
        <template #body="slotProps">
          {{ capitalizeString(slotProps.data['name']) }}
        </template>
      </Column>
      <Column
        field="identityNumber"
        :header="t('detail.contract.header.identityNumber')"
        header-class="font-bold"
        class="table__identityNumber"
      >
        <template #body="slotProps">
          {{ `${slotProps.data['identityNumber']}` }}
        </template>
      </Column>
      <Column
        field="address"
        :header="t('detail.contract.header.address')"
        header-class="font-bold"
        class="table__address"
      >
        <template #body="slotProps">
          {{ `${slotProps.data['address']}` }}
        </template>
      </Column>
      <Column
        field="city"
        :header="t('detail.contract.header.city')"
        header-class="font-bold"
        class="table__city"
      >
        <template #body="slotProps">
          {{ `${slotProps.data['city']}` }}
        </template>
      </Column>
      <Column
        :header="t('detail.header.actions')"
        header-class="table__header font-bold"
        class="table__actions"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            <Button
              v-tooltip.top="t('detail.contract.actions.update')"
              rounded
              class="button button-normal mr-2"
              @click="handleUpdate(slotProps.data)"
            >
              <template #icon>
                <svg-icon
                  :name="userRole.name === Roles.support ? 'vision' : 'edit'"
                  size="16"
                  color="#626868"
                />
              </template>
            </Button>
            <BaseConfirmDeletePopup ref="popup" />
            <Button
              v-if="!(userRole.name === Roles.support)"
              v-tooltip.top="t('detail.contract.actions.close')"
              icon="pi pi-times"
              rounded
              class="button button-remove"
              @click="handleRemove($event, slotProps.data)"
            />
          </div>
        </template>
      </Column>
      <template #footer>
        <BasePaginator
          v-model:current-page="currentPage"
          v-model:rows-per-page="rowsPerPage"
          :totalRecords
          @refresh-data="getContracts"
        />
      </template>
    </DataTable>
  </div>
  <ContractCreationDialog
    v-model:visible="activeUpdateDialog"
    updating
    :updated-contract="updatedContract"
    :updated-contract-id="updatedContract?.id"
    :toasting="toast"
    @refresh-contracts="getContracts()"
  />
  <ContractCreationDialog
    v-model:visible="activeCreationDialog"
    :toasting="toast"
    @refresh-contracts="getContracts()"
  />
  <BasePushNotification group="success" icon-name="success" color="#00DB7F" />
  <BasePushNotification group="error" icon-name="error" color="#EA2839" />
</template>

<style scoped>
::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

::v-deep(.table__reference) {
  width: 10%;
}

::v-deep(.table__name) {
  width: 16%;
}

::v-deep(.table__identityNumber) {
  width: 7%;
}

::v-deep(.table__address) {
  width: 15%;
}
::v-deep(.table__city) {
  width: 10%;
}

::v-deep(.table__billing) {
  width: 24%;
}

::v-deep(.table__energySupplied) {
  width: 11%;
}

::v-deep(.table__actions) {
  width: 16%;
}
</style>
