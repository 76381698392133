<script setup lang="ts">
import { t } from '@/common/i18n'
import { computed, onMounted, ref } from 'vue'
import { FilterMatchMode } from '@primevue/core/api'
import { useToast } from 'primevue/usetoast'
import { storeToRefs } from 'pinia'
import { useApi } from '@/stores/api/api'
import { useAuthStore } from '@/stores/auth'
import { ApiService } from '@/services/ApiService'
import { type Card, Roles, type Station } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { getStatusCard } from '@/utils/status'
import BaseConfirmDeletePopup from '@/components/ui/BaseConfirmDeletePopup.vue'
import BaseLottieAnimation from '@/components/ui/BaseLottieAnimation.vue'
import BasePushNotification from '@/components/ui/BasePushNotification.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import clock from '@/assets/lottie/clock.json'
import AppTopbar from '@/layout/AppTopbar.vue'
import BaseBadge from '@/components/ui/BaseBadge.vue'
import { capitalizeString } from '@/utils/capitalize'
import CardCreationDialog from '@/components/cards/CardCreationDialog.vue'
import type { CardResponse } from '@/models/domain/card/api/Card'
import { UnitOfMeasure } from '@/models/ocpp/enums.ts'
import BasePaginator from '@/components/ui/BasePaginator.vue'

const { loading } = storeToRefs(useApi())
const { role: userRole } = useAuthStore()
const cards = ref<Card[]>([])

const totalRecords = ref<number>(0)
const rowsPerPage = ref<number>(10)
const currentPage = ref<number>(0)
const activeCreationDialog = ref<boolean>(false)
const activeUpdateDialog = ref<boolean>(false)
const toast = useToast()
const popup = ref()
// TODO: Decide whether to go with stations or locations
const stations = ref<Partial<Station>[]>([])
const updatedCard = ref<Partial<Card>>()
const updatedCardId = ref<string>('')

const filters = ref({
  global: { value: undefined, matchMode: FilterMatchMode.CONTAINS },
  active: { value: null, matchMode: FilterMatchMode.IN },
  'organization.name': { value: [], matchMode: FilterMatchMode.IN }
})
const statusRangeHours = ref({
  true: t('status.enabled'),
  false: t('status.disabled')
})
const statusesActive = ref([
  { name: t('status.enabled'), status: 'enabled', code: true },
  { name: t('status.disabled'), status: 'disabled', code: false }
])

const getCards = async () => {
  try {
    const response = await ApiService.readAllEntities<CardResponse>(
      `${RouteNamespace.cards}?limit=${rowsPerPage.value}&offset=${currentPage.value}`
    )
    cards.value = response['cards']
    totalRecords.value = response['totalRecords']
  } catch (error) {
    console.error('Error retrieving cards:', error)
  }
}
const getStationNames = async () => {
  try {
    stations.value = await ApiService.readAllEntities(
      `${RouteNamespace.stations}/${RouteNamespace.names}`
    )
  } catch (error) {
    console.error('Error retrieving customers:', error)
  }
}
const handleAddItem = () => {
  activeCreationDialog.value = true
}
const handleUpdate = async (row: Card) => {
  updatedCard.value = {
    active: row.active,
    alias: row.alias,
    expiryDate: row.expiryDate,
    idTag: row.idTag,
    limitKWh: row.limitKWh,
    stations: row.stations,
    organization: row.organization
  }
  updatedCardId.value = row.id
  activeUpdateDialog.value = true
}
const handleRemove = async (event: Event, card: string) => {
  await popup.value.showConfirmPopup(
    event,
    async () => {
      loading.value = true
      await actionsRemoveCard(card)
      loading.value = false
    },
    undefined
  )
}
const actionsRemoveCard = async (id: string) => {
  try {
    await ApiService.deleteEntity(`${RouteNamespace.cards}/${id}`)
    toast.add({
      group: 'success',
      severity: 'success',
      summary: t('detail.card.notifications.deleteSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error removing card:', error)
  } finally {
    await getCards()
  }
}

const evaluateRangeHours = computed(
  () =>
    (isActive: boolean): string =>
      statusRangeHours.value[isActive.toString()] || ''
)
const organizations = computed(() =>
  Object.values(
    cards.value.reduce((acc, card) => {
      acc[card.organization.id] = card.organization
      return acc
    }, {})
  )
)
onMounted(async () => {
  try {
    loading.value = true
    await Promise.all([getCards(), getStationNames()])
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
  } finally {
    loading.value = false
  }
})
</script>
<template>
  <AppTopbar>
    <template #header>
      <div class="flex flex-column h-4rem text-3xl">
        <div class="flex flex-row ml-2 justify-content-between align-items-center">
          <div class="flex">
            <svg-icon name="rfid-card" size="24" color="#626868" />
            <span class="font-bold ml-2 text-2xl">{{ t('dashboard.cards') }}</span>
          </div>
          <Button
            v-if="userRole.name !== Roles.support && userRole.name !== Roles.viewer"
            v-tooltip.top="t('detail.card.actions.create')"
            class="button button-normal ml-3"
            rounded
            @click="handleAddItem"
          >
            <template #icon>
              <svg-icon name="add" size="18" color="#626868" />
            </template>
          </Button>
        </div>
      </div>
    </template>
    <template #body>
      <div class="flex flex-row flex-wrap row-gap-2">
        <IconField icon-position="left" class="mr-2">
          <InputIcon class="pi pi-search"></InputIcon>
          <InputText
            v-model="filters['global'].value"
            :placeholder="t('search')"
            type="text"
            :pt="{
              root: {
                class: ['border-300']
              }
            }"
          />
        </IconField>
        <MultiSelect
          v-if="userRole.name === Roles.admin"
          v-model="filters['organization.name'].value"
          class="multi-select col-3 align-items-center"
          display="chip"
          :placeholder="t('detail.card.header.placeholder.organization')"
          :options="organizations"
          optionLabel="name"
          optionValue="name"
        >
          <template #option="slotProps">
            <div class="flex align-items-center">
              {{ capitalizeString(slotProps.option.name) }}
            </div>
          </template>
        </MultiSelect>
        <MultiSelect
          v-model="filters['active'].value"
          class="multi-select ml-2 align-items-center"
          display="chip"
          :placeholder="t('detail.location.header.placeholder.status')"
          :options="statusesActive"
          optionLabel="name"
          optionValue="code"
        >
          <template #option="slotProps">
            <div class="flex align-items-center">
              <div :class="`status status__${slotProps.option.status} mr-1`" />
              <small
                class="font-bold"
                :class="`badgeStatus badgeStatus__${slotProps.option.status}`"
                >{{ capitalizeString(slotProps.option.name) }}</small
              >
            </div>
          </template>
        </MultiSelect>
      </div>
    </template>
  </AppTopbar>
  <div class="card h-fit bg-white shadow-none">
    <DataTable
      v-model:filters="filters"
      :value="cards"
      :rows="rowsPerPage"
      :globalFilterFields="['alias', 'organization.name', 'organization.email', 'idTag', 'active']"
      dataKey="id"
    >
      <template #empty>
        <BaseLottieAnimation :icon="clock" :label="t('detail.card.notFound')" />
      </template>
      <Column
        v-if="userRole.name === Roles.admin"
        field="organization"
        :header="t('detail.user.header.organization')"
        header-class="font-bold"
        class="table__organization-name"
      >
        <template #body="slotProps">
          {{ capitalizeString(slotProps.data['organization']?.['name']) }}
        </template>
      </Column>
      <Column
        field="alias"
        :header="t('detail.card.header.alias')"
        header-class="font-bold"
        class="table__alias"
      >
        <template #body="slotProps">
          {{ `${slotProps.data['alias']}` }}
        </template>
      </Column>
      <Column
        field="id"
        :header="t('detail.card.header.id')"
        header-class="font-bold"
        class="table__id"
      >
        <template #body="slotProps">
          {{ `${slotProps.data['idTag']}` }}
        </template>
      </Column>
      <Column
        field="expiryDate"
        :header="t('detail.card.header.expiredDate')"
        header-class="font-bold"
        class="table__expiry"
      >
        <template #body="slotProps">
          {{
            slotProps.data['expiryDate']
              ? new Date(slotProps.data['expiryDate'].split('T')[0]).toLocaleDateString()
              : '-'
          }}
        </template>
      </Column>
      <Column
        field="kwhLinked"
        :header="t('detail.card.header.kwhLinked')"
        header-class="font-bold"
        class="table__energy"
      >
        <template #body="slotProps">
          <div class="flex flex-row">
            {{ `${slotProps.data['limitKWh']} ${UnitOfMeasure.KWH}` }}
          </div>
        </template>
      </Column>
      <Column
        field="status"
        :header="t('detail.card.header.status')"
        header-class="font-bold"
        class="table__status"
      >
        <template #body="slotProps">
          <BaseBadge
            rounded
            outlined
            :style-header="`badgeStatus badgeStatus__${getStatusCard(slotProps.data['active'])}`"
            :style-content="`status status__${getStatusCard(slotProps.data['active'])}`"
            :content="evaluateRangeHours(slotProps.data['active'])"
          />
        </template>
      </Column>
      <Column
        v-if="userRole.name !== Roles.viewer"
        :header="t('detail.header.actions')"
        header-class="table__header font-bold"
        class="table__actions"
      >
        <template #body="slotProps">
          <div class="flex flex-row justify-content-center">
            <Button
              class="button button-normal mr-2"
              v-tooltip.top="t('detail.card.actions.update')"
              rounded
              @click="handleUpdate(slotProps.data)"
            >
              <template #icon>
                <svg-icon
                  :name="[Roles.support, Roles.viewer].includes(userRole.name) ? 'vision' : 'edit'"
                  size="20"
                  color="#626868"
                />
              </template>
            </Button>
            <BaseConfirmDeletePopup ref="popup" />
            <Button
              v-if="![Roles.support, Roles.viewer].includes(userRole.name)"
              class="button button-remove"
              v-tooltip.top="t('detail.card.actions.delete')"
              rounded
              @click="handleRemove($event, slotProps.data.id)"
            >
              <template #icon>
                <svg-icon name="trash" size="18" />
              </template>
            </Button>
          </div>
        </template>
      </Column>
      <template #footer>
        <BasePaginator
          v-model:current-page="currentPage"
          v-model:rows-per-page="rowsPerPage"
          :totalRecords
          @refresh-data="getCards"
        />
      </template>
    </DataTable>
  </div>
  <CardCreationDialog
    v-model:visible="activeCreationDialog"
    :available-stations="stations"
    :toasting="toast"
    @refresh-cards="getCards"
  />
  <CardCreationDialog
    v-model:visible="activeUpdateDialog"
    :available-stations="stations"
    updating
    :updated-card="updatedCard"
    :updated-card-id="updatedCardId"
    :toasting="toast"
    @refresh-cards="getCards"
  />
  <BasePushNotification group="success" icon-name="success" color="#00DB7F" />
  <BasePushNotification group="error" icon-name="error" color="#EA2839" />
</template>

<style scoped lang="scss">
::v-deep(.p-inputswitch ::before) {
  background-color: var(--white) !important;
}

::v-deep(.p-inputswitch-checked ::before) {
  background-color: var(--action-activate) !important;
}

::v-deep(.table__header) > div > span {
  margin: 0 auto;
}

::v-deep(.table__organization-name) {
  width: 15%;
}

::v-deep(.table__alias) {
  width: 18%;
}

::v-deep(.table__id) {
  width: 16%;
}

::v-deep(.table__expiry) {
  width: 16%;
}

::v-deep(.table__energy) {
  width: 13%;
}

::v-deep(.table__status) {
  width: 17%;
}

::v-deep(.table__actions) {
  width: 10%;
}

::v-deep(.multi-select) {
  min-width: 11rem;
}
</style>
