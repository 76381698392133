<script setup lang="ts">
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import type { ToastServiceMethods } from 'primevue/toastservice'
import { useForm } from 'vee-validate'
import { object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { t } from '@/common/i18n'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import { type Organization } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import type { FileUploadEvent } from '@/models/ui/FileUpload'
import { ApiService } from '@/services/ApiService'
import { useApi } from '@/stores/api/api'

const { updatedOrganization, toasting } = defineProps<{
  updatedOrganization?: Partial<Organization>
  toasting: ToastServiceMethods
}>()

const visible = defineModel<boolean>('visible')

const emit = defineEmits<{
  (e: 'refreshOrganizations'): void
}>()

const { loading } = storeToRefs(useApi())

const schema = toTypedSchema(
  object({
    uploadedImage: string().required().default('')
  })
)

const { defineField, handleSubmit, resetForm, meta } = useForm({
  validationSchema: schema
})

const [uploadedImage] = defineField('uploadedImage')

const imageFile = ref<File | null>(null)

const onSubmit = handleSubmit(async () => {
  try {
    loading.value = true
    const formData = new FormData()
    if (imageFile.value) formData.append('imageProfile', imageFile.value)
    await ApiService.updateEntityWithResources<Organization>(
      `${RouteNamespace.organizations}/${updatedOrganization?.id}`,
      formData
    )
    toasting.add({
      group: 'success',
      severity: 'success',
      summary: t('detail.organization.notifications.updateSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error occurred while uploading organization image:', error)
    toasting.add({
      group: 'error',
      severity: 'error',
      summary: t('detail.organization.notifications.updateError'),
      life: 3000
    })
  } finally {
    visible.value = false
    emit('refreshOrganizations')
    loading.value = false
  }
})

const handleCancel = () => {
  visible.value = false
}

const handleUpload = (event: FileUploadEvent) => {
  const file = event.files && event.files[event.files.length - 1]
  if (file) {
    const reader = new FileReader()
    reader.onload = () => {
      imageFile.value = file
      uploadedImage.value = URL.createObjectURL(file)
    }
    reader.readAsDataURL(file)
  }
  event.files.pop()
}

const removeImage = () => {
  imageFile.value = null
  uploadedImage.value = ''
}

watch(visible, () => {
  imageFile.value = null
  uploadedImage.value = ''
  resetForm()
})
</script>

<template>
  <BaseDialog v-model:visible="visible" :closable="false">
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-4 ml-3">
        <p class="p-dialog-title">{{ t('detail.organization.header.image') }}</p>
      </div>
    </template>
    <template #header>
      <div class="absolute top-0 right-0 mt-4 mr-3">
        <svg-icon name="organization" size="24" color="white" />
      </div>
    </template>
    <template #body>
      <div class="flex flex-column">
        <div class="p-3">
          <label class="font-family-light" for="upload">{{
            t('detail.organization.actions.upload')
          }}</label>
        </div>
        <div class="flex flex-row gap-6 p-3">
          <Avatar
            class="border-1 border-100 avatar w-5rem h-5rem"
            shape="circle"
            :image="uploadedImage"
          >
            <template #default>
              <svg-icon v-if="!uploadedImage" name="user" size="48" color="#9E9E9E" />
            </template>
          </Avatar>
          <div class="flex flex-column gap-2">
            <FileUpload
              class="button button-normal border-round-2xl w-full"
              mode="basic"
              name="demo[]"
              auto
              url="/api/upload"
              @select="handleUpload"
              accept="image/*"
              :maxFileSize="1000000"
              :chooseLabel="t('detail.settings.actions.uploadImage')"
              :pt="{
                content: { class: 'hidden' },
                buttonbar: { class: 'p-0 border-none' }
              }"
            />
            <Button
              class="button w-full"
              icon="pi pi-trash"
              rounded
              severity="danger"
              :label="t('detail.settings.actions.removeImage')"
              @click="removeImage"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog @cancel="handleCancel" @confirm="onSubmit" remove :disabled="!meta.valid" />
      </div>
    </template>
  </BaseDialog>
</template>

<style scoped lang="css">
::v-deep(.avatar img) {
  object-fit: cover;
}
</style>
