import { defineStore } from 'pinia'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { ApiService } from '@/services/ApiService'
import type { State } from '@/stores/api/types/state'

export const useApi = defineStore('api', {
  state: (): State => ({
    totalTransactions: 0,
    totalUsers: 0,
    totalCards: 0,
    loading: false,
    chargers: [],
    connectorTypes: [],
    transactions: [],
    roles: [],
    contractEmails: [],
    organizationEmails: []
  }),
  getters: {
    getChargers: (state) => state.chargers,
    getConnectorTypes: (state) => state.connectorTypes,
    getTransactions: (state) => state.transactions,
    getTotalTransactions: (state) => state.totalTransactions,
    getTotalUsers: (state) => state.totalUsers,
    getTotalCards: (state) => state.totalCards,
    getRoles: (state) => state.roles,
    getLoading: (state) => state.loading,
    getContractEmails: (state) => state.contractEmails,
    getOrganizationEmails: (state) => state.organizationEmails
  },
  actions: {
    async fetchChargers() {
      this.chargers = await ApiService.readAllEntities(RouteNamespace.chargers)
      return this.chargers
    },
    async fetchTransactions() {
      this.transactions = await ApiService.readAllEntities(RouteNamespace.transactions)
      return this.transactions
    },
    async fetchRoles() {
      this.roles = await ApiService.readAllEntities(RouteNamespace.roles)
      return this.roles
    },
    async fetchConnectorTypes() {
      this.connectorTypes = await ApiService.readAllEntities(RouteNamespace.connectorsTypes)
      return this.connectorTypes
    },
    async fetchContractEmails() {
      this.contractEmails = await ApiService.readAllEntities(
        `${RouteNamespace.contracts}/${RouteNamespace.emails}`
      )
      return this.contractEmails
    },
    async fetchOrganizationEmails() {
      this.organizationEmails = await ApiService.readAllEntities(
        `${RouteNamespace.organizations}/${RouteNamespace.emails}`
      )
      return this.organizationEmails
    }
  }
})
