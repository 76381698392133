<script setup lang="ts">
import { computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useForm, useIsFieldValid } from 'vee-validate'
import { array, boolean, date, number, object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { t } from '@/common/i18n'
import { useApi } from '@/stores/api/api'
import { useAuthStore } from '@/stores/auth'
import { ApiService } from '@/services/ApiService'
import BaseInputSwitch from '@/components/ui/BaseInputSwitch.vue'
import BaseInputNumber from '@/components/ui/BaseInputNumber.vue'
import BaseDialog from '@/components/ui/BaseDialog.vue'
import FooterDialog from '@/components/partials/FooterDialog.vue'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import type { ToastServiceMethods } from 'primevue/toastservice'
import { type Card, Roles, type Station } from '@/models'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { UnitOfMeasure } from '@/models/ocpp/enums.ts'

const { availableStations, updatedCard, updatedCardId, updating, toasting } = defineProps<{
  availableStations: Partial<Station>[]
  updatedCard?: Partial<Card>
  updatedCardId?: string
  updating?: boolean
  toasting: ToastServiceMethods
}>()

const visible = defineModel<boolean>('visible')

const emit = defineEmits<{
  (e: 'refreshCards'): void
}>()

const { loading, organizationEmails } = storeToRefs(useApi())
const { role: userRole, organizations: userOrganizations } = useAuthStore()

const schema = toTypedSchema(
  object({
    active: boolean().default(false),
    alias: string()
      .max(100, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required')),
    organizationId: string()
      .uuid()
      .when((_, schema) =>
        userRole.name === Roles.admin ||
        (userRole.name === Roles.manager && userOrganizations.length > 1)
          ? schema.required(() => t('validation.required')).default('')
          : schema.notRequired()
      ),
    expiryDate: date().nullable().default(null),
    idTag: string()
      .max(20, ({ max }) => t('validation.max', { max }))
      .required(() => t('validation.required')),
    limitKWh: number()
      .max(25, ({ max }) => t('validation.max', { max }))
      .default(0),
    stations: array().default([])
  })
)

const { defineField, handleSubmit, resetForm, errors, meta, setFieldValue } = useForm({
  validationSchema: schema
})

const [active] = defineField('active')
const [alias] = defineField('alias')
const [organizationId] = defineField('organizationId')
const [expiryDate] = defineField('expiryDate')
const [idTag] = defineField('idTag')
const [limitKWh] = defineField('limitKWh')
const [stations] = defineField('stations')

const isOrgIdValid = useIsFieldValid('organizationId')

const filteredStations = computed(() =>
  userRole.name === Roles.admin || (userRole.name === Roles.manager && userOrganizations.length > 1)
    ? availableStations.filter(
        (station) => station?.location?.organization?.id === organizationId.value
      )
    : availableStations
)

const onSubmit = handleSubmit(async (values) => {
  try {
    loading.value = true
    if (updatedCardId) {
      await ApiService.updateEntity(RouteNamespace.cards, updatedCardId, {
        ...values,
        organizationId: values.organizationId ? values.organizationId : userOrganizations[0].id
      })
    } else {
      await ApiService.createEntity(RouteNamespace.cards, {
        ...values,
        organizationId: values.organizationId ? values.organizationId : userOrganizations[0].id
      })
    }
    toasting.add({
      group: 'success',
      severity: 'success',
      summary: updating
        ? t('detail.card.notifications.updateSuccess')
        : t('detail.card.notifications.createSuccess'),
      life: 3000
    })
  } catch (error) {
    console.error('Error occurred while fetching data:', error)
    toasting.add({
      group: 'error',
      severity: 'error',
      summary: updating
        ? t('detail.card.notifications.updateError')
        : t('detail.card.notifications.createError'),
      life: 3000
    })
  } finally {
    visible.value = false
    emit('refreshCards')
    loading.value = false
  }
})

const handleCancel = () => {
  visible.value = false
}

const resetStationsField = () => {
  setFieldValue('stations', [])
}

watch(visible, () => {
  resetForm({
    values: {
      ...updatedCard,
      stations: updatedCard?.stations?.map((station) => station.id),
      organizationId: updatedCard?.organization?.id
    }
  })
})
</script>

<template>
  {{ '' /* TODO: Error message layout integration */ }}
  <BaseDialog
    v-model:visible="visible"
    :closable="false"
    :style="{ width: '50vw', minWidth: '44rem', maxWidth: '55rem' }"
  >
    <template #title>
      <div class="absolute top-0 left-0 mt-4 mb-5 ml-4">
        <p class="p-dialog-title">
          {{ updating ? t('detail.header.editCard') : t('detail.header.addCard') }}
        </p>
      </div>
      <div class="absolute top-0 right-0 mt-4 mb-4 mr-3">
        <svg-icon name="rfid-card" size="24" color="white" />
      </div>
    </template>

    <template #body>
      <div
        v-if="
          userRole.name === Roles.admin ||
          (userRole.name === Roles.manager && userOrganizations.length > 1)
        "
        class="flex flex-row"
      >
        <div class="field col-12">
          <label for="organizationId" class="font-family-light required">{{
            t('detail.location.dialog.owner')
          }}</label>
          <Select
            @change="resetStationsField"
            v-model="organizationId"
            id="organizationId"
            aria-describedby="organizationId-help"
            class="h-3rem align-items-center"
            filter
            optionLabel="name"
            optionValue="id"
            :options="userRole.name === Roles.admin ? organizationEmails : userOrganizations"
            :invalid="!!errors.organizationId"
            :emptyMessage="t('detail.organization.notFound')"
            :placeholder="t('detail.location.dialog.placeholder.owner')"
            :pt="{
              item: ({ context }) => ({
                class: context.selected
                  ? 'bg-gray-300'
                  : context.focused
                    ? 'bg-gray-100'
                    : undefined
              })
            }"
          >
            <template #dropdownicon>
              <div class="flex flex-column justify-content-center p-0 col-12">
                <svg-icon name="arrow-down" size="18" color="#9E9E9E" />
              </div>
            </template>
          </Select>
          <small id="organizationId-help" class="p-error">
            {{ errors.organizationId }}
          </small>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="field col-6">
          <label class="font-family-light required" for="alias">{{
            t('detail.card.dialog.alias')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="rate-alias" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="alias"
              id="alias"
              type="text"
              aria-describedby="alias-help"
              :disabled="userRole.name === Roles.support || userRole.name === Roles.viewer"
              :invalid="!!errors.alias"
              :placeholder="t('detail.card.dialog.placeholder.alias')"
            />
          </IconField>
          <small id="alias-help" class="p-error">
            {{ errors.alias }}
          </small>
        </div>
        <div class="field col-6">
          <label class="font-family-light required" for="alias">{{
            t('detail.card.dialog.identifier')
          }}</label>
          <IconField icon-position="left">
            <InputIcon>
              <svg-icon name="rfid-card" size="18" color="#9E9E9E" />
            </InputIcon>
            <InputText
              v-model="idTag"
              id="idTag"
              :disabled="
                !!updating || userRole.name === Roles.support || userRole.name === Roles.viewer
              "
              type="text"
              aria-describedby="idTag-help"
              :invalid="!!errors.idTag"
              :placeholder="t('detail.card.dialog.placeholder.identifier')"
            />
          </IconField>
          <small id="idTag-help" class="p-error">
            {{ errors.idTag }}
          </small>
        </div>
      </div>
      <div class="flex flex-row align-items-center">
        <div class="field col-6">
          <label class="font-family-light" for="expiryDate">{{
            t('detail.card.dialog.expiredDateCard')
          }}</label>
          <DatePicker
            v-model="expiryDate"
            id="expiryDate"
            aria-describedby="expiryDate-help"
            class="shadow-none"
            showButtonBar
            :disabled="userRole.name === Roles.support || userRole.name === Roles.viewer"
            :minDate="new Date()"
            :invalid="!!errors.expiryDate"
            :placeholder="t('detail.card.dialog.placeholder.expiredDateCard')"
          />
          <small id="expiryDate-help" class="p-error">
            {{ errors.expiryDate }}
          </small>
        </div>
        <div class="field col-6">
          <label class="font-family-light" for="limitKWh">{{
            t('detail.card.dialog.limitKWH')
          }}</label>
          <BaseInputNumber
            v-model="limitKWh"
            id="limitKWh"
            aria-describedby="limitKWh-help"
            showButtons
            :disabled="userRole.name === Roles.support || userRole.name === Roles.viewer"
            :suffix="`${UnitOfMeasure.KWH}`"
            :min="0"
            :step="0.1"
            :invalid="!!errors.limitKWh"
            :placeholder="t('detail.card.dialog.placeholder.limitCard')"
          />
          <small id="limitKWh-help" class="p-error">
            {{ errors.limitKWh }}
          </small>
        </div>
      </div>

      <div class="flex flex-row align-items-center">
        <div class="field col-6">
          <label class="font-family-light" for="stations">{{
            t('detail.card.dialog.stations')
          }}</label>
          <MultiSelect
            v-model="stations"
            id="stations"
            aria-describedby="stations-help"
            class="h-3rem align-items-center"
            display="chip"
            optionLabel="alias"
            optionValue="id"
            :disabled="
              userRole.name === Roles.support
                ? true
                : userRole.name === Roles.admin ||
                    (userRole.name === Roles.manager && userOrganizations.length > 1)
                  ? !isOrgIdValid
                  : false
            "
            :options="filteredStations"
            :invalid="!!errors.stations"
            :placeholder="t('detail.card.dialog.placeholder.locationsToCard')"
            :pt="{
              item: ({ context }) => ({
                class: context.selected
                  ? 'bg-gray-300'
                  : context.focused
                    ? 'bg-gray-100'
                    : undefined
              })
            }"
          >
            <template #dropdownicon>
              <div class="flex flex-column justify-content-center p-0 col-12">
                <svg-icon name="arrow-down" size="18" color="#9E9E9E" />
              </div>
            </template>
          </MultiSelect>
          <small id="stations-help" class="p-error">
            {{ errors.stations }}
          </small>
        </div>
        <div class="field col-6">
          <div class="h-3rem">
            <label class="font-family-light" for="active">{{
              t('detail.card.dialog.activation')
            }}</label>
          </div>
          <div class="flex flex-column">
            <div class="flex flex-row align-items-center mb-1">
              <BaseInputSwitch
                v-model="active"
                id="active"
                aria-describedby="active-help"
                :disabled="userRole.name === Roles.support || userRole.name === Roles.viewer"
              />
              <div class="ml-3 text-600">
                {{
                  active
                    ? t('detail.card.dialog.status.active')
                    : t('detail.card.dialog.status.inactive')
                }}
              </div>
            </div>
          </div>
          <small id="active-help" class="p-error">
            {{ errors.active }}
          </small>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex xl:flex-row sm:flex-column justify-content-end mt-4">
        <FooterDialog
          @cancel="handleCancel"
          @confirm="onSubmit"
          remove
          :disabled="
            !meta.valid || userRole.name === Roles.support || userRole.name === Roles.viewer
          "
        />
      </div>
    </template>
  </BaseDialog>
</template>
