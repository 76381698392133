<script setup lang="ts">
interface InputNumberProps {
  placeholder?: string
  suffix?: string
  min?: number
  max?: number
  step?: number
  id?: string
  ariaDescribedby?: string
  invalid?: boolean
  onInput?: () => void
  pt?: object
  disabled?: boolean
}

const {
  placeholder,
  suffix,
  step,
  min,
  max,
  id,
  ariaDescribedby,
  invalid,
  pt = {},
  onInput,
  disabled = false
} = defineProps<InputNumberProps>()

const value = defineModel<number>()
</script>

<template>
  <InputNumber
    v-model="value"
    :id="id"
    :aria-describedby="ariaDescribedby"
    :invalid="invalid"
    showButtons
    :suffix="`${suffix ? suffix : ''}`"
    buttonLayout="horizontal"
    incrementButtonClass="action-increment"
    incrementButtonIcon="pi pi-plus"
    decrementButtonClass="action-decrement"
    decrementButtonIcon="pi pi-minus"
    @input="onInput"
    :disabled
    :min="min"
    :max="max"
    :step="step"
    :placeholder="placeholder"
    :pt="pt"
  />
</template>

<style scoped lang="scss">
::v-deep(.p-inputnumber-input) {
  border-radius: 0 !important;
  border: 1px solid var(--midGrayDetail) !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
}
::v-deep(.p-inputnumber-increment-button) {
  color: white;
}
::v-deep(.p-inputnumber-decrement-button) {
  color: white;
}
</style>
