<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'

import { useVOnboarding, VOnboardingStep, VOnboardingWrapper } from 'v-onboarding'
import 'v-onboarding/dist/style.css'
import AppMenuItem from './AppMenuItem.vue'
import { t } from '@/common/i18n'
import { Roles } from '@/models'
import { capitalizeString } from '@/utils/capitalize'
import { ApiService } from '@/services/ApiService'
import { RouteNamespace } from '@/models/common/RouteNameSpace'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import SvgIcon from '@/common/icons/SvgIcon.vue'
import { useLayout } from '@/layout/composables/layout'
import type { OnboardingStep } from '@/layout/types/onBoarding.ts'

const authStore = useAuthStore()
const { onMenuToggle, onWindowResize, isCollapsed } = useLayout()
const { userId, onBoarded, imageProfile, role } = storeToRefs(authStore)
const wrapper = ref(null)
const { start, finish } = useVOnboarding(wrapper)
const outsideClickListener = ref<((event: MouseEvent) => void) | null>(null)
const topbarMenuActive = ref(false)
const hasProfileImage = ref(false)
const pathImage = ref('')

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = () => {
      topbarMenuActive.value = false
    }
    document.addEventListener('click', outsideClickListener.value)
  }
}

const endingOutBoarding = async () => {
  finish()
  await ApiService.updateEntity(RouteNamespace.users, userId.value, { onBoarded: true })
  useAuthStore().onBoarded = true
}
const shouldShowStep = (step: OnboardingStep, role: Roles): boolean => {
  return step.roles.includes(role)
}
const allSteps = computed<OnboardingStep[]>(() => [
  {
    id: 'welcome',
    element: '#welcome',
    title: t('onBoarding.welcome.title'),
    description: t('onBoarding.welcome.description'),
    roles: [Roles.admin, Roles.manager, Roles.owner, Roles.support, Roles.viewer]
  },
  {
    id: 'contract',
    element: '#contract',
    title: t('onBoarding.contracts.title'),
    description: t('onBoarding.contracts.description'),
    roles: [Roles.admin, Roles.support]
  },
  {
    id: 'organization',
    element: '#organization',
    title: t('onBoarding.organizations.title'),
    description: t('onBoarding.organizations.description'),
    roles: [Roles.admin, Roles.manager, Roles.support]
  },
  {
    id: 'location',
    element: '#location',
    title: t('onBoarding.locations.title'),
    description: t('onBoarding.locations.description'),
    roles: [Roles.admin, Roles.manager, Roles.owner, Roles.support, Roles.viewer]
  },
  {
    id: 'user',
    element: '#user',
    title: t('onBoarding.users.title'),
    description: t('onBoarding.users.description'),
    roles: [Roles.admin, Roles.manager, Roles.owner, Roles.support]
  },
  {
    id: 'rfid-card',
    element: '#rfid-card',
    title: t('onBoarding.cards.title'),
    description: t('onBoarding.cards.description'),
    roles: [Roles.admin, Roles.manager, Roles.owner, Roles.support, Roles.viewer]
  },
  {
    id: 'rate',
    element: '#rate',
    title: t('onBoarding.rates.title'),
    description: t('onBoarding.rates.description'),
    roles: [Roles.admin, Roles.manager, Roles.owner, Roles.support]
  },
  {
    id: 'transaction',
    element: '#transaction',
    title: t('onBoarding.transactions.title'),
    description: t('onBoarding.transactions.description'),
    roles: [Roles.admin, Roles.manager, Roles.owner, Roles.support]
  }
])
const steps = computed(() => {
  return allSteps.value
    .filter((step) => shouldShowStep(step, role.value.name))
    .map((step) => ({
      attachTo: { element: step.element },
      content: {
        title: step.title,
        description: step.description
      }
    }))
})
const menu = computed(() => [
  {
    label: t('sidebar.management'),
    icon: 'management',
    roles: [],
    items: [
      {
        label: t('sidebar.dashboard'),
        to: '/dashboard',
        icon: 'home',
        roles: [Roles.admin, Roles.manager, Roles.owner, Roles.support, Roles.viewer]
      },
      {
        label: t('sidebar.contracts'),
        to: '/contracts',
        icon: 'contract',
        roles: [Roles.admin, Roles.support]
      },
      {
        label: t('sidebar.organizations'),
        to: '/organizations',
        icon: 'organization',
        roles: [Roles.admin, Roles.manager, Roles.support]
      },
      {
        label: t('sidebar.locations'),
        to: '/locations',
        icon: 'location',
        roles: [Roles.admin, Roles.manager, Roles.owner, Roles.support, Roles.viewer]
      },
      {
        label: t('sidebar.users'),
        to: '/users',
        icon: 'user-face',
        roles: [Roles.admin, Roles.manager, Roles.owner]
      }
    ]
  },
  {
    label: t('sidebar.monetize'),
    icon: 'monetize',
    roles: [],
    items: [
      {
        label: t('sidebar.cards'),
        to: '/cards',
        icon: 'rfid-card',
        roles: [Roles.admin, Roles.manager, Roles.owner, Roles.support, Roles.viewer]
      },
      {
        label: t('sidebar.rates'),
        to: '/rates',
        icon: 'rate',
        roles: [Roles.admin, Roles.manager, Roles.owner, Roles.support]
      },
      {
        label: t('sidebar.transactions'),
        to: '/transactions',
        icon: 'transaction',
        roles: [Roles.admin, Roles.manager, Roles.owner, Roles.support]
      }
    ]
  }
])
const preferencesMenu = computed(() => [
  {
    label: t('sidebar.help'),
    icon: 'help',
    to: '/help',
    roles: [Roles.admin, Roles.manager, Roles.owner, Roles.support, Roles.viewer],
    footerItem: true
  },
  {
    label: t('sidebar.settings'),
    icon: 'settings',
    to: '/settings',
    roles: [Roles.admin, Roles.manager, Roles.owner, Roles.support, Roles.viewer],
    footerItem: true
  },
  {
    label: t('detail.settings.actions.logout'),
    icon: 'logout',
    to: '/logout',
    roles: [Roles.admin, Roles.manager, Roles.owner, Roles.support, Roles.viewer],
    footerItem: true
  }
])

const handleImageError = () => {
  hasProfileImage.value = false
}

const checkViewportWidth = () => {
  onWindowResize(window.innerWidth < 1366)
}

const getReturnImageProfile = async () => {
  return (await ApiService.readResourceByEntity(RouteNamespace.users, userId.value)) as Blob
}
const updateImage = async () => {
  hasProfileImage.value = false
  const response = await getReturnImageProfile()
  if (response instanceof Blob && response.size > 2) {
    pathImage.value = URL.createObjectURL(response)
    hasProfileImage.value = true
  } else {
    console.log('No image received from the server')
    pathImage.value = ''
  }
}

watch(
  () => authStore.imageProfile,
  (newImageProfile) => {
    if (newImageProfile) {
      pathImage.value = newImageProfile
    } else {
      pathImage.value = ''
    }
  },
  { immediate: true }
)
onMounted(() => {
  if (!onBoarded.value) start()

  if (imageProfile.value !== '') {
    updateImage()
  }
  window.addEventListener('resize', checkViewportWidth)
  checkViewportWidth()
  bindOutsideClickListener()
})

onUnmounted(() => {
  window.removeEventListener('resize', checkViewportWidth)
})
</script>

<template>
  <VOnboardingWrapper ref="wrapper" :steps="steps" @exit="endingOutBoarding">
    <template #default="{ previous, next, step, isFirst, isLast, index }">
      <VOnboardingStep>
        <div class="flex flex-row onboarding-layout shadow sm:rounded-lg justify-content-between">
          <div class="px-4 py-5 sm:p-4">
            <div class="flex flex-row">
              <div v-if="step.content">
                <span v-if="step.content.title" class="text-lg font-bold leading-6 text-gray-900">
                  {{ step.content.title }}
                </span>
                <div
                  v-if="step.content.description"
                  class="mt-3 max-w-xl text-sm text-gray-500 text-justify"
                >
                  <p>{{ step.content.description }}</p>
                </div>
              </div>
              <div class="font-family-light text-sm">{{ `${index + 1}/${steps.length}` }}</div>
            </div>
            <div class="flex flex-row justify-content-between mt-3">
              <Button
                @click="previous"
                rounded
                class="button button-normal"
                size="small"
                :class="isFirst ? 'visible' : ''"
              >
                {{ t('onBoarding.actions.last') }}
              </Button>
              <Button
                v-if="!isLast"
                @click="next"
                rounded
                class="button button-onboarding"
                size="small"
              >
                {{ t('onBoarding.actions.next') }}
              </Button>
              <Button
                v-if="isLast"
                @click="endingOutBoarding"
                rounded
                class="button button-onboarding"
                size="small"
              >
                {{ t('onBoarding.actions.finish') }}
              </Button>
            </div>
            <div v-if="!isFirst" class="flex justify-content-end mt-3" @click="endingOutBoarding">
              <span class="cursor-pointer underline text-sm">{{
                t('onBoarding.actions.skip')
              }}</span>
            </div>
          </div>
        </div>
      </VOnboardingStep>
    </template>
  </VOnboardingWrapper>
  <aside class="layout-menu">
    <section>
      <div id="welcome">
        <div
          v-if="hasProfileImage"
          :class="
            isCollapsed
              ? 'flex flex-row justify-content-start pl-2'
              : 'flex flex-row justify-content-center pl-3'
          "
        >
          <Avatar
            v-if="isCollapsed"
            class="border-1 border-100 avatar"
            size="xlarge"
            shape="circle"
            :image="pathImage"
          />
          <Image
            v-else
            :src="pathImage"
            alt="Image"
            :hidden="isCollapsed"
            imageClass="logo"
            @error="handleImageError"
          />
        </div>
        <div v-else class="flex flex-row justify-content-between w-full">
          <div class="flex justify-content-center align-items-center">
            <svg-icon name="chargevite" size="60" color="#EA2839" />
            <span class="font-bold text-4xl">{{ capitalizeString(t('brandName')) }}</span>
          </div>
        </div>
      </div>
      <div class="mt-6">
        <ul>
          <template v-for="(section, sectionIndex) in menu" :key="sectionIndex">
            <AppMenuItem :item="section" :index="sectionIndex" :visible="!isCollapsed" />
          </template>
        </ul>
      </div>
    </section>
    <section>
      <ul>
        <template v-for="(section, sectionIndex) in preferencesMenu" :key="sectionIndex">
          <AppMenuItem :item="section" :index="sectionIndex" :visible="!isCollapsed" />
        </template>
      </ul>
      <button
        aria-label="Toggle sidebar"
        class="p-link layout-menu-button layout-topbar-button"
        :class="{ 'button-collapsed': isCollapsed }"
        @click="onMenuToggle()"
      >
        <i class="layout-menu-button__icon pi pi-angle-double-left"></i>
        <span class="layout-menu-button__text ml-2">{{ t('actions.collapse') }}</span>
      </button>
    </section>
  </aside>
</template>
<style scoped lang="scss">
.onboarding-layout {
  background-color: var(--borderGray);
  margin-left: 1rem;
  border-radius: 6px;
  z-index: 1;
}

.visible {
  visibility: hidden;
}

::v-deep(.logo) {
  max-height: 150px;
  max-width: 100%;
}

::v-deep(.avatar img) {
  object-fit: cover;
}
</style>
