<script setup lang="ts">
import { GeocoderAutocomplete } from '@geoapify/geocoder-autocomplete'
import { onMounted, onUnmounted, ref } from 'vue'
import { t } from '@/common/i18n'

const autocompleteRef = ref<GeocoderAutocomplete | null>(null)
const { disabled = false, ...props } = defineProps<{
  placeholder: string
  disabled?: boolean
  required?: boolean
  address?: string
  errors?: string
}>()
const emits = defineEmits(['select-address', 'modify-address'])

const initialConfig = () => {
  const apiKey = import.meta.env.VITE_GEOAPIFY
  const options = {
    skipDetails: true,
    debounceDelay: 300,
    placeholder: props.placeholder || ' ',
    countrycodes: ['es', 'fr', 'en']
    /* Geocoder options */
  }
  const item = document.getElementById('address')
  if (item) {
    autocompleteRef.value = new GeocoderAutocomplete(item, apiKey, options)
    autocompleteRef.value.on('select', (location) => {
      if (location) {
        emits('select-address', location['properties'])
      }
    })
    autocompleteRef.value.on('input', (input) => {
      emits('modify-address', input)
    })
    autocompleteRef.value.setValue(props.address || '')
    const input = item.querySelector('input')
    const div = item.querySelector('div')
    if (input && disabled) {
      input.setAttribute('disabled', 'true')
    }
    if (div && disabled) {
      div.setAttribute('style', 'display: none')
    }
  }
}
onMounted(() => {
  initialConfig()
})
onUnmounted(() => {
  autocompleteRef.value?.off('select')
  autocompleteRef.value?.off('input')
})
</script>

<template>
  <div class="flex flex-row">
    <svg-icon name="address" size="18" class="mr-2" color="#9E9E9E" />
    <label for="address" :class="required ? 'required' : ''">{{
      t('detail.organization.header.address')
    }}</label>
  </div>
  <div id="address" :class="`relative ${errors ? 'invalid' : ''}`" />
  <small id="address-help" class="p-error">
    {{ errors }}
  </small>
</template>

<style lang="scss">
.geoapify-autocomplete-input {
  margin-top: 0.5em;
  width: 100% !important;
  height: 41px !important;
  font-family: inherit;
  font-feature-settings: inherit;
  font-size: 1rem;
  color: var(--p-inputtext-color);
  background: var(--p-inputtext-background);
  padding: var(--p-inputtext-padding-y) var(--p-inputtext-padding-x);
  border: 1px solid var(--p-inputtext-border-color);
  transition:
    background var(--p-inputtext-transition-duration),
    color var(--p-inputtext-transition-duration),
    border-color var(--p-inputtext-transition-duration),
    outline-color var(--p-inputtext-transition-duration),
    box-shadow var(--p-inputtext-transition-duration);
  appearance: none;
  border-radius: var(--p-inputtext-border-radius);
  outline-color: transparent;
  box-shadow: var(--p-inputtext-shadow);
}

.geoapify-autocomplete-input:focus {
  border: 1px solid var(--lightGrayDetail) !important;
}

.geoapify-autocomplete-input:hover {
  border-color: var(--p-inputtext-hover-border-color);
}

.invalid > .geoapify-autocomplete-input {
  border-color: var(--p-inputtext-invalid-border-color);
}
</style>
